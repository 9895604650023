import styled from "styled-components";

export const StyledBurger = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: none;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    display: flex;
    margin-right: 1.6875rem;
  }
`;
