import styled from "styled-components";

export const StyledSubSectionElement = styled.div`
  display: flex;
  margin-bottom: 3.375rem;
  img {
    width: 100%;
    display: flex;
    align-self: center;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    flex-direction: column;
    margin-bottom: 0;
    padding: 3.125rem 0;
    border-bottom: 1px solid grey;
    &.quality {
      border-bottom: 0;
    }
    img {
      margin: 0;
      margin-top: 1.125rem;
      width: 100%;
    }
  }
`;

export const ImgContainer = styled.div`
  flex: 1;
  margin: 1em;
  padding-top: 8.8rem;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding-top: 0;
  }
`

export const Caption = styled.div<{ left?: boolean }>`
  ${props => props.left ? 'text-align: left;' : 'text-align: right;'}
  margin-top: 0.5rem;
  color: #ffd05d;
  border: 0.5rem solid #ffd05d;
  padding: 2.1875rem 2.5rem;
  font-size: 2.2rem;
  text-transform: uppercase;
  font-weight: bold;
  font-family: Roboto,sans-serif;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-left: 0;
    margin-right: 0;
    font-size: 1rem;
    text-align: left;
  }

`
