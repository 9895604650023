import { chunk } from "lodash";
import { number } from "prop-types";
import React, { Fragment } from "react";
import { Element } from "react-scroll";
import SubSectionElement from "../../components/menu/SubSectionElement/SubSectionElement";
import MultipleColumnTextSection from "../../components/MultipleColumnTextSection/MultipleColumnTextSection";
import sections from "../../data/sections/sections";
import { StyledCentralSection } from "./CentralSection.styled";

const CentralSection = ({ appWidth }) => {
  return (
    <StyledCentralSection appWidth={appWidth}>
      {chunk(sections, 2).map((s, i) => {
        return (
          <Fragment key={i}>
            <Element name={s[0].id}>
              <SubSectionElement s={s[0]} indexImg={0} />
            </Element>
            <Element name={s[1].id}>
              <SubSectionElement s={s[1]} indexImg={1} />
            </Element>
          </Fragment>
        );
      })}
      <div
        style={{ borderTop: "1px solid rgb(151,151,151)", paddingTop: "3rem" }}
      >
        <MultipleColumnTextSection
          labelsIDs={["coming-soon-first-column", "coming-soon-second-column"]}
          appWidth={appWidth}
        />
      </div>
    </StyledCentralSection>
  );
};

CentralSection.propTypes = {
  appWidth: number
};

export default CentralSection;
