import styled from "styled-components";

export const StyledInitialSection = styled.div`
  margin: auto;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 11.6875rem 0;
  text-align: left;
  background-color: #fef6e0;
  background-image: ${({ backgroundImageUrl }) => backgroundImageUrl};
  background-position: top;

  ${({ style }) => style}

  .YourSafetyBox {
    color: white;
    background-color: rgba(0, 70, 173, 0.77);
    padding: 2rem 5.6875rem 2rem 2.75rem;
    max-width: 28rem;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 2.375rem;
    margin-bottom: 3.75rem;
    text-align: left;
  }

  .InitialSectionText {
    max-width: 32.5rem;
    text-align: left;
    font-size: 1.5rem;
    line-height: 1.5em;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding-top: 8.125rem;
    font-size: 1.25rem;
    background-image: ${({ backgroundImageMobileUrl }) =>
      backgroundImageMobileUrl};
    .YourSafetyBox {
      background-color: transparent;
      color: #0046ad;
      font-size: 2.125rem;
    }

    .InitialSectionText {
      font-size: 1.25rem;
      max-width: 16.25rem;
    }
  }
`;
