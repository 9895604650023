import styled from "styled-components";

export const StyledHeader = styled.div`
  background-color: #0046ad;
  height: 100px;
  color: white;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 1;
  width: 100%;

  .bocconiLogo {
    text-align: left;
    align-self: center;
    margin-left: 1.6875rem;
  }

  .bocconiLogo img {
    width: 160px;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    .bocconiLogo {
      width: auto;
      margin-left: 1.6875rem;
    }
  }
`;
