import { number } from "prop-types";
import React from "react";
import { FormattedHTMLMessage } from "react-intl";
import Button from "../../components/Button/Button";
import config from "../../data/config";
import getImage from "../../utils/utils";
import { StyledInitialSection } from "./InitialSection.styled";

const { courseLink } = config;

const InitialSection = ({ appWidth }) => {
  return (
    <StyledInitialSection
      appWidth={appWidth}
      backgroundImageUrl={`url(${getImage("initialSection")})`}
      backgroundImageMobileUrl={`url(${getImage("initialSectionMobile")})`}
    >
      <div style={{ marginLeft: "3rem" }}>
        <p className="InitialSectionText">
          <FormattedHTMLMessage id="initialSection-text" />
        </p>
        <a href={courseLink} rel="noopener noreferrer" target="_blank">
          <Button className="InitialSectionButton">
            <FormattedHTMLMessage id="access-to-training" />
          </Button>
        </a>
      </div>
    </StyledInitialSection>
  );
};

InitialSection.propTypes = {
  appWidth: number
};

export default InitialSection;
