import styled from "styled-components";

export const StyledMultipleColumnTextSection = styled.div`
  max-width: ${({ appWidth }) => `${appWidth}px`};
  margin: auto;
  text-align: left;
  flex-direction: column;

  img {
    width: 46.125rem;
    margin-right: 4.3125rem;
  }
  div {
    display: flex;
  }

  .MultipleColumnTextColumns {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .MultipleColumnTextColumns p {
    flex: 1;
    margin: 1em;
  }

  .SubSectionWithImage {
    margin-bottom: 9rem;
  }

  .SubSectionWithImage p {
    margin-top: 2rem;
    font-size: 1.125rem;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    max-width: none;
    margin-top: 0.5rem;

    div,
    img {
      margin: auto;
    }

    img {
      margin: auto;
      max-width: 16.25rem;
    }

    p {
      margin: auto;
    }

    div,
    .MultipleColumnTextColumns {
      flex-direction: column;
    }

    .MultipleColumnTextColumns {
    }

    .SubSectionWithImage {
      margin-bottom: 3.125rem;
    }
  }
`;

export const StyledMultipleColumnTitle = styled.b`
  font-size: 2.8125rem;
  margin-bottom: 3.0625rem;
  text-align: center;
  margin: 0.5em;
  line-height: 1em;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    text-align: left;
  }
`
