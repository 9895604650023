import React from "react";
import { StyledLanguageSelector } from "./LanguageSelector.styles";
import { FormattedHTMLMessage } from "react-intl";

interface LanguageSelectorProps {
  changeLanguage: any;
  language: string;
  languages: string[];
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  changeLanguage,
  language,
  languages
}) => {
  return (
    <StyledLanguageSelector>
      {languages.map((l, i) => (
        <p
          style={{
            cursor: "pointer",
            fontWeight: l === language ? "bold" : "normal",
            opacity: l === language ? 1 : 0.7,
            borderRight: i === 0 ? '2px solid white' : 'none',
            paddingRight: i === 0 ? '7px' : '0',
            paddingLeft: i === 0 ? '0' : '7px',
          }}
          key={i}
          onClick={() => {
            changeLanguage(l, language);
          }}
        >
          <FormattedHTMLMessage id={l} />
        </p>
      ))}
    </StyledLanguageSelector>
  );
};

export default LanguageSelector;
