import { number } from "prop-types";
import React from "react";
import { FormattedHTMLMessage } from "react-intl";
import { StyledMultipleColumnTextSection, StyledMultipleColumnTitle } from "./MultipleColumnTextSection.styled";

const MultipleColumnTextSection = ({ appWidth, titleID, labelsIDs }) => {
  return (
    <StyledMultipleColumnTextSection appWidth={appWidth}>
      {titleID && (
        <StyledMultipleColumnTitle>
          <FormattedHTMLMessage id={titleID} />
        </StyledMultipleColumnTitle>
      )}
      <div className="MultipleColumnTextColumns">
        {labelsIDs.map(lID => {
          return (
            <p key={lID}>
              <FormattedHTMLMessage id={lID} />
            </p>
          );
        })}
      </div>
    </StyledMultipleColumnTextSection>
  );
};

MultipleColumnTextSection.propTypes = {
  appWidth: number,
  labelsIDs: Array,
  titleID: String
};

export default MultipleColumnTextSection;
