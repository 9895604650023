import styled from "styled-components";

export const StyledPresentationSection = styled.div`
  max-width: ${({ appWidth }) => `${appWidth}px`};
  margin: auto;
  padding-bottom: 4.25rem;
  margin-top: 9.3125rem;
  text-align: left;
  border-bottom: 1px solid rgb(151, 151, 151);

  img {
    width: 46.125rem;
    margin-right: 4.3125rem;
  }
  div {
    display: flex;
  }

  .PresentationColumns {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .PresentationColumns p span {
    display: flex;
    max-width: 90%;
  }

  .SubSectionWithImage {
    margin-bottom: 9rem;
  }

  .SubSectionWithImage p {
    margin-top: 2rem;
    font-size: 1.125rem;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    max-width: none;
    margin-top: 0.5rem;
    width: 88%;

    div,
    img {
      margin: auto;
    }

    img {
      margin: auto;
      max-width: 16.25rem;
    }

    p {
      margin: auto;
    }

    div,
    .PresentationColumns {
      flex-direction: column;
      padding: 0;
    }

    .PresentationColumns {
      padding-bottom: 3.125rem;
    }

    .SubSectionWithImage {
      padding-bottom: 3.125rem;
      margin-bottom: 3.125rem;
    }
  }
`;
