import styled from "styled-components";

export const StyledLanguageSelector = styled.div`
  display: flex;
  margin-left: 5.875rem;
  margin-right: 6.125rem;

  .divider {
    margin 0 0.5rem;
  }

  img {
    width: 1.5rem;
    margin-right: 0.5rem;
  }




  @media (max-width: ${({ theme }) => theme.mobile}) {
    justify-content: center;
    align-items: center;
    height: 5rem;
    margin:auto;


    p {
      margin: 0;
    }

    .divider {
      margin 0 1rem;
    }

    
  }
`;
