import en from './en.json'
import it from './it.json'

type DictionariesType = {
  [index: string]:any
}

const dictionaries: DictionariesType = {
  it,
  en
}

export default dictionaries