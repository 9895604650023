import { number } from "prop-types";
import React from "react";
import MultipleColumnTextSection from "../../components/MultipleColumnTextSection/MultipleColumnTextSection";
import { StyledPresentationSection } from "./PresentationSection.styled";

const PresentationSection = ({ appWidth }) => {
  const labelsIDs = ["presentation-first-column", "presentation-second-column"];
  return (
    <StyledPresentationSection appWidth={appWidth}>
      <MultipleColumnTextSection
        titleID={"values-development-respect"}
        labelsIDs={labelsIDs}
      />
    </StyledPresentationSection>
  );
};

PresentationSection.propTypes = {
  appWidth: number
};

export default PresentationSection;
