import styled from "styled-components";

export const StyledCentralSection = styled.div`
  max-width: ${({ appWidth }) => `${appWidth}px`};
  margin: auto;
  padding-top: 5rem;

  > div:nth-child(2n) img {
    margin-left: 0;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    display: flex;
    flex-direction: column;
    padding: 2rem;

    div.safety {
      padding-top: 0;
    }

    div:nth-child(2n) div {
      flex-direction: column-reverse;
    }

    > div:nth-child(2n) img {
      margin-right: 0;
    }
  }
`;