import { object, string } from "prop-types";
import React from "react";
import { StyledButton } from "./Button.styled";

const Button = ({ children, style, className, disabled = false }) => {
  return (
    <StyledButton style={{ ...style }} className={className} disabled={disabled}>
      {children}
    </StyledButton>
  );
};

Button.propTypes = {
  style: object,
  className: string
};

export default Button;
