import queryString from "query-string";
import React from "react";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "styled-components";
import "./App.css";
import dictionaries from "./data/dictionaries";
import { theme } from "./data/theme";
import CentralSection from "./sections/CentralSection/CentralSection";
import Footer from "./sections/Footer/Footer";
import Header from "./sections/Header/Header";
import InitialSection from "./sections/InitialSection/InitialSection";
import PresentationSection from "./sections/PresentationSection/PresentationSection";

interface IProps {}

interface IState {
  language: string;
}

const languages = Object.keys(dictionaries).map(k => k);

class App extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      language: this.getLanguageFromUrl(
        queryString.parse(window.location.search)
      )
    };
  }

  getLanguageFromUrl(parsedQuery: any) {
    const l = parsedQuery["l"];
    return l && languages.includes(l) ? l : "en";
  }

  changeLanguage(l: string, language: string) {
    if (l !== language) {
      var newLanguage = language === "it" ? "en" : "it";

      this.setState({
        language: newLanguage
      });
      window.location.search = `l=${newLanguage}`;
    }
  }

  render() {
    const { language } = this.state;

    const appWidth = 1330;

    const dictionary = dictionaries[`${language}`];

    return (
      <ThemeProvider theme={theme}>
        <IntlProvider locale={language} messages={dictionary}>
          <div className="App">
            <Header
              changeLanguage={this.changeLanguage.bind(this)}
              language={language}
              languages={languages}
            />
            <InitialSection appWidth={appWidth} />
            <PresentationSection appWidth={appWidth} />
            <CentralSection appWidth={appWidth} />
            <Footer appWidth={appWidth} />
          </div>
        </IntlProvider>
      </ThemeProvider>
    );
  }
}

export default App;
