import styled from "styled-components";

export const StyledButton = styled.button`
  color: #f9866e;
  border: 0.125rem solid #f9866e;
  background-color: white;
  max-width: 80%;
  padding: 1.1875rem 2.5rem;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: bold;
  ${props => !props.disabled && 'cursor: pointer;'}
  font-family: Roboto, sans-serif;
  ${props => props.disabled && 'opacity: 0.5;'}

  &.InitialSectionButton {
    background-color: #f9866e;
    color: white;
    margin-top: 3.25rem;
  }

  &.InitialSectionButton:hover {
    background-color: rgba(249, 134, 110, 0.65);
    border: 0.125rem solid transparent;
  }

  ${props => !props.disabled && `
    &:hover {
      background-color: rgb(249, 134, 110);
      color: white;
    }
  `}

  @media (max-width: ${({ theme }) => theme.mobile}) {
    font-size: 1.125rem;
  }
`;
