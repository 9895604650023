import React, { useState } from "react";
import Hamburger from "../../components/menu/Hamburger/Hamburger";
import Menu from "../../components/menu/Menu/Menu";
import getImage from "../../utils/utils";
import { StyledHeader } from "./Header.styled";

interface HeaderProps {
  changeLanguage: any;
  language: string;
  languages: Object[];
}

const Header: React.FC<HeaderProps> = ({
  changeLanguage,
  language,
  languages
}) => {
  const [open, setOpen] = useState(false);

  return (
    <StyledHeader>
      <div className="bocconiLogo">
        <a
          target="_blank"
          href="https://www.unibocconi.it/"
          rel="noopener noreferrer"
        >
          <img alt={getImage("bocconiLogo")} src={getImage("bocconiLogo")} />
        </a>
      </div>
      <Menu
        open={open}
        setOpen={setOpen}
        changeLanguage={changeLanguage}
        language={language}
        languages={languages}
      />
      <Hamburger open={open} setOpen={setOpen} />
    </StyledHeader>
  );
};

export default Header;
