import bocconiLogo from "../data/images/bocconiLogo.png";
import en from "../data/images/en.png";
import hamburger from "../data/images/hamburger.png";
import initialSection from "../data/images/initialSection.png";
import initialSectionMobile from "../data/images/initialSectionMobile.png";
import it from "../data/images/it.png";
import liability from "../data/images/liability.png";
import presentation from "../data/images/presentation.png";
import privacy from "../data/images/privacy.png";
import quality from "../data/images/quality.png";
import safety from "../data/images/safety.png";
import { ImagesType } from "../types/types";

const Images: ImagesType = [
  { id: "safety", img: safety },
  { id: "privacy", img: privacy },
  { id: "liability", img: liability },
  { id: "quality", img: quality },
  { id: "bocconiLogo", img: bocconiLogo },
  { id: "initialSection", img: initialSection },
  { id: "initialSectionMobile", img: initialSectionMobile },
  { id: "hamburger", img: hamburger },
  { id: "presentation", img: presentation },
  { id: "it", img: it },
  { id: "en", img: en }
];

export default Images;
