import React from "react";
import { FormattedHTMLMessage } from "react-intl";
import config from "../../../data/config";
import getImage from "../../../utils/utils";
import Button from "../../Button/Button";
import { StyledSubSectionElement, Caption, ImgContainer } from "./SubSectionElement.styled";

interface SubSectionElementProps {
  s: {
    id: string
    link: string
    disabled: boolean
  };
  indexImg: number;
}

const { courseLink } = config;

const SubSectionElement: React.FC<SubSectionElementProps> = ({
  s,
  indexImg
}) => {
  return (
    <StyledSubSectionElement className={s.id}>
      {
        indexImg === 1 
          ? (
            <ImgContainer>
              <img alt={getImage(s.id)} src={getImage(s.id)} />
              <Caption left>
                <FormattedHTMLMessage id={`central-section-${s.id}-caption`} />
              </Caption>
            </ImgContainer>
          )
          : null
      }
      <div
        className="central-section-text-container"
        style={{
          color: "#333333",
          display: "flex",
          flexDirection: "column",
          textAlign: "left",
          flex: 1,
          margin: '1em'
        }}
      >
        <b
          style={{
            color: "#333333",
            fontSize: "2.8125rem",
            lineHeight: '1.6em'
          }}
        >
          <FormattedHTMLMessage id={`central-section-${s.id}-title`} />
        </b>
        <span
          style={{
            color: "#707070",
            fontSize: "1.75rem",
            marginBottom: "2.4375rem"
          }}
        >
          <FormattedHTMLMessage id={`central-section-${s.id}-subtitle`} />
        </span>
        <FormattedHTMLMessage id={`central-section-${s.id}-text`} />
        <div style={{ display: "inline-block" }}>
          {
            s.disabled
              ? (
                <Button style={{ marginTop: "3.1875rem" }} disabled={s.disabled}>
                  <FormattedHTMLMessage id="cooming-soon" />
                </Button>
              )
              : (
                <a href={courseLink} rel="noopener noreferrer" target="_blank">
                  <Button style={{ marginTop: "3.1875rem" }} disabled={s.disabled}>
                    <FormattedHTMLMessage id="access-to-course" />
                  </Button>
                </a>
              )
          }
          
        </div>
      </div>
      {
        indexImg === 0 
          ? (
            <ImgContainer>
              <img alt={getImage(s.id)} src={getImage(s.id)} /> 
              <Caption>
                <FormattedHTMLMessage id={`central-section-${s.id}-caption`} />
              </Caption>
            </ImgContainer>
          )
          : null
      }
    </StyledSubSectionElement>
  );
};

export default SubSectionElement;
