import styled from "styled-components";

export const StyledFooter = styled.div`
  background-color: #0046ad;
  color: white;
  padding-top: 3.125rem;
  padding-bottom: 1.875rem;
  margin-top: 4rem;
  font-weight: 300;

  .FooterContainer {
    max-width: ${({ appWidth }) => `${appWidth}px`};
    margin: auto;
  }

  .FooterContentContainer {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: 25%;
    justify-items: start;
    text-align: left;
    justify-content: center;
    line-height: 1.6rem;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    .FooterContentContainer {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      text-align: center;
      justify-items: center;
    }
  }
`;
