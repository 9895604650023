import { bool, func } from "prop-types";
import React from "react";
import { Link } from "react-scroll";
import menuItems from "../../../data/sections/sections";
import LanguageSelector from "../../LanguageSelector/LanguageSelector";
import { StyledMenu } from "./Menu.styled";

const Menu = ({ open, setOpen, changeLanguage, language, languages }) => {
  return (
    <StyledMenu open={open}>
      <ul>
        {menuItems.map(mi => (
          <Link
            activeClass="active"
            className={mi.id}
            to={mi.id}
            spy={true}
            smooth={true}
            duration={500}
            key={mi.id}
            offset={-120}
            onClick={() => setOpen(!open)}
          >
            <li style={{ fontWeight: 600, cursor: "pointer" }} key={mi.id}>
              {mi.id}
            </li>
          </Link>
        ))}
      </ul>
      <LanguageSelector
        changeLanguage={changeLanguage}
        language={language}
        languages={languages}
      />
    </StyledMenu>
  );
};

Menu.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired
};

export default Menu;
