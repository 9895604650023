import { number } from "prop-types";
import React from "react";
import { FormattedHTMLMessage } from "react-intl";
import config from "../../data/config";
import { StyledFooter } from "./Footer.styled";

const Footer = ({ appWidth }) => {
  const { email } = config;

  return (
    <StyledFooter appWidth={appWidth}>
      <div className="FooterContainer">
        <div className="FooterContentContainer">
          <div className="columnContainer">
            <p className="cellWithStyle">
              <FormattedHTMLMessage id="university" />
            </p>
          </div>
          <div className="columnContainer">
            <div className="cellWithStyle">
              <p
                style={{
                  fontSize: "1.125rem",
                  alignSelf: "center"
                }}
              >
                <b>
                  <FormattedHTMLMessage id={"compliance"} />
                </b>
              </p>
              <FormattedHTMLMessage id="safety" />
            </div>
          </div>
          <div className="columnContainer">
            <p
              style={{
                fontSize: "1.125rem",
                alignSelf: "center"
              }}
            >
              <b>
                <FormattedHTMLMessage id={"contacts"} />
              </b>
            </p>
            <p className="cellWithStyle">
              <a
                href={`mailto:${email}`}
                style={{
                  color: "white"
                }}
              >
                {email}
              </a>
            </p>
          </div>
        </div>
        <p
          style={{
            marginBottom: 0,
            marginTop: "6.25rem",
            fontWeight: 300
          }}
        >
          <FormattedHTMLMessage id="copyright-symbol" />{" "}
          {new Date().getFullYear()}
          <FormattedHTMLMessage id="copyright" />
        </p>
      </div>
    </StyledFooter>
  );
};

Footer.propTypes = {
  appWidth: number
};

export default Footer;
