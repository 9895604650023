import styled from "styled-components";

export const StyledMenu = styled.div`
  display: flex;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    width: 23.5625rem;
    align-items: center;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    display: ${({ open }) => (open ? "flex" : "none")};
    position: fixed;
    background-color: rgb(0, 70, 173);
    flex-direction: column;
    width: 100%;
    top: 100px;
    right: 0;
    justify-content: center;

    ul {
      flex-direction: column;
      width: 100%;
      border-top: 1px solid white;
    }

    ul a {
      border-bottom: 1px solid white;
      width: 100%;
      height: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
